import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Layout/Layout.js";
import SEO from '../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Inspirations" mdxType="SEO" />
    <h1>{`Mes inspirations`}</h1>
    <p>{`Cette page recense mes différentes inspirations, avec des blog de patisserie bien sûr mais aussi quelques livres.`}</p>
    <h2>{`Recette`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://blog.feeriecake.fr/gateaux-glacage-miroir/"
        }}>{`Nappage miroir façon Lenotre`}</a></li>
    </ul>
    <h2>{`Blog`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://lacuisinedethomas.fr/"
        }}>{`La cuisine de Thomas`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.iletaitunefoislapatisserie.com/"
        }}>{`Il était une fois la pâtisserie`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cestpasdelatarte.com/"
        }}>{`C’est pas de la tarte`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://nathaliebakes.com/accueil/"
        }}>{`Nathalie Bakes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://empreintesucree.fr/"
        }}>{`Empreintes sucrées`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.undejeunerdesoleil.com"
        }}>{`Un déjeuner de soleil`}</a></li>
    </ul>
    <h2>{`Livres`}</h2>
    <ul>
      <li parentName="ul">{`La pâtisserie de `}<a parentName="li" {...{
          "href": "https://fr.wikipedia.org/wiki/Cyril_Lignac"
        }}>{`Cyril Lignac`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      